<template>
  <div class="d-flex">
    <strategy-parts-ref-bar
      @click-expand="expand = $event"
      defaultParts="targetgoal"
      :noteMode="true"
      :ribbonTxt="'戦略パーツを参照する'"
    ></strategy-parts-ref-bar>
    <div class="review-wrap" v-show="!expand" >
      <v-container class="container" v-if="isInit">
        <!-- 戻るボタン -->
        <v-row>
          <v-col align="start">
            <v-btn text color="btPrimary" dark @click="$router.back()">
              <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
              <span class="black--text">前のページに戻る</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <!-- パーツタイトル -->
          <v-col cols="12" class="text-h5 font-weight-medium pb-1" align="center">
            <span>プロジェクト振り返り</span>
          </v-col>
          <!-- パーツ説明文 -->
          <v-col cols="12" class="pa-0">
            <hr class="page-title-hr" />
          </v-col>
          <v-col cols="12" class="pt-1 mb-3" align="left">
            <!-- 説明文がある場合はここに入ります -->
          </v-col>
          <!-- パーツ表示部 -->
          <v-col>
            <v-form ref="form" style="width: 100%">
              <v-row class="sp-common-width-narrow mx-auto" no-gutters>
                <v-col cols="12" class="mb-3">
                  <parts-element-label label="対象期間" />
                </v-col>
                <v-row class="mb-4">
                  <v-col>
                    <!-- 対象期間 -->
                    <v-menu
                      v-model="dPickerTargetStartDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="targetStartDate"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="targetStartDate"
                        @input="dPickerTargetStartDate = false"
                        :readonly="readonly"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <div class="mx-10 d-flex align-center">～</div>
                  <v-col>
                    <v-menu
                      v-model="dPickerTargetEndDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="targetEndDate"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="targetEndDate"
                        @input="dPickerTargetEndDate = false"
                        :readonly="readonly"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- 事業目標達成状況 -->
                <v-col cols="12" class="mb-3">
                  <parts-element-label label="事業目標達成状況" />
                </v-col>
                <!-- 売上 -->
                <v-row no-gutters>
                  <v-col cols="3" class="my-auto">売上</v-col>
                  <v-col cols="2" class="text-subtitle-2 my-auto">期間実績<br>/目標</v-col>
                  <v-col cols="7" class="d-flex align-center">
                    <div>
                      <div class="text-caption">実績数</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-3">
                        <custom-currency-field
                          v-model="evaluation.termSalesResults"
                          label="実績数"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div class="mr-3 mt-3" style="padding-top; 20px;">/</div>
                    <div>
                      <div class="text-caption">目標数</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-5">
                        <custom-currency-field
                          v-model="evaluation.termSalesTarget"
                          label="目標数"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div>
                      <div class="text-caption">達成率</div>
                      <v-responsive :height="40" class="d-flex align-center">
                        {{ calcPercentage(evaluation.termSalesResults, evaluation.termSalesTarget) }} %
                      </v-responsive>
                    </div>
                  </v-col>
                </v-row>
                <!-- 顧客数 -->
                <v-row no-gutters class="mb-5">
                  <v-col cols="3" class="my-auto">顧客数</v-col>
                  <v-col cols="2" class="text-subtitle-2 my-auto">期間実績<br>/目標</v-col>
                  <v-col cols="7" class="d-flex align-center">
                    <div>
                      <v-responsive :maxWidth="120" :height="40" class="me-3">
                        <custom-currency-field
                          v-model="evaluation.termCustomerResults"
                          label="実績数"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div class="mr-3 mt-3" style="padding-top; 20px;">/</div>
                    <div>
                      <v-responsive :maxWidth="120" :height="40" class="me-5">
                        <custom-currency-field
                          v-model="evaluation.termCustomerTarget"
                          label="目標数"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div>
                      <v-responsive :height="40" class="d-flex align-center">
                        {{ calcPercentage(evaluation.termCustomerResults, evaluation.termCustomerTarget) }} %
                      </v-responsive>
                    </div>
                  </v-col>
                </v-row>
                <!-- 予算消化状況 -->
                <v-col cols="12" class="mb-3">
                  <parts-element-label label="予算消化状況" />
                </v-col>
    
                <!-- 予算 -->
                <v-row no-gutters class="mb-5">
                  <v-col cols="3" class="my-auto">予算</v-col>
                  <v-col cols="2" class="text-subtitle-2 my-auto">使用額<br>/予算</v-col>
                  <v-col cols="7" class="d-flex align-center">
                    <div>
                      <div class="text-caption">使用額</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-3">
                        <custom-currency-field
                          v-model="evaluation.termUsage"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div class="mr-3 mt-3" style="padding-top; 20px;">/</div>
                    <div>
                      <div class="text-caption">予算</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-5">
                        <custom-currency-field
                          v-model="evaluation.termBudget"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div>
                      <div class="text-caption">消化率</div>
                      <v-responsive :height="40" class="d-flex align-center">
                        {{ calcPercentage(evaluation.termUsage, evaluation.termBudget) }} %
                      </v-responsive>
                    </div>
                  </v-col>
                </v-row>
                <!-- ステップ別目標達成状況 -->
                <v-col cols="12" class="mb-3">
                  <parts-element-label label="ステップ別目標達成状況" />
                </v-col>
                <v-row no-gutters v-for="(item, idx) in evaluation.termTarget" :key="idx">
                  <v-col cols="3" class="my-auto">
                    <div class="text-caption">目標{{idx + 1}}</div>
                      <v-responsive :height="40" class="me-5">
                        <v-text-field
                          v-model="item.termTargetName"
                          :readonly="readonly"
                          dense
                          outlined
                        ></v-text-field>
                      </v-responsive>
                  </v-col>
                  <v-col cols="2" class="text-subtitle-2 my-auto">期間実績<br>/目標</v-col>
                  <v-col cols="7" class="d-flex align-center">
                    <div>
                      <div class="text-caption">実績数</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-3">
                        <custom-currency-field
                          v-model="item.termResultsAmount"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div class="mr-3 mt-3" style="padding-top; 20px;">/</div>
                    <div>
                      <div class="text-caption">目標数</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-5">
                        <custom-currency-field
                          v-model="item.termTargetAmount"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div>
                      <div class="text-caption">達成率</div>
                      <v-responsive :height="40" class="d-flex align-center">
                        {{ calcPercentage(item.termResultsAmount, item.termTargetAmount) }} %</v-responsive>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div class="py-1">
                      <v-btn v-show="!readonly" icon small color="red" @click="deleteTermTarget(idx)">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="2" class="text-subtitle-2 my-auto">実績単価<br>/目標単価</v-col>
                  <v-col cols="7" class="d-flex align-center">
                    <div>
                      <div class="text-caption">実績単価</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-3">
                        <custom-currency-field
                          v-model="item.termResultsUnitprice"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div class="mr-3 mt-3" style="padding-top; 20px;">-</div>
                    <div>
                      <div class="text-caption">目標単価</div>
                      <v-responsive :maxWidth="120" :height="40" class="me-5">
                        <custom-currency-field
                          v-model="item.termTargetUnitprice"
                          :readonly="readonly"
                          dense
                          maxLength="17"
                        ></custom-currency-field>
                      </v-responsive>
                    </div>
                    <div>
                      <div class="text-caption">目標差</div>
                      <v-responsive :height="40" class="d-flex align-center">
                        ￥{{ Number(item.termResultsUnitprice - item.termTargetUnitprice).toLocaleString() }}
                      </v-responsive>
                    </div>
                  </v-col>
                </v-row>
                <v-col cols="12" class="text-center my-5">
                  <common-add-btn
                    v-show="!project.archivedAt && hasWritePermission"
                    color="btPrimary"
                    @click="addTermTarget()"
                    small
                  />
                </v-col>
    
                <!-- Good/Bad分析 -->
                <v-col cols="12" class="mb-3">
                  <parts-element-label label="Good/Bad分析" />
                </v-col>
                <v-col cols="12" class="px-3">
                  <div class="text-caption">・Good要因</div>
                  <v-combobox
                    v-model="goodFactor"
                    :items="MasterData.evaluationGoodFactor"
                    placeholder=""
                    outlined
                    multiple
                    small-chips
                    deletable-chips
                    hide-details
                    :clearable="!readonly"
                    dense
                    class="affiliated-user"
                    :readonly="readonly"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" class="px-3 mb-4">
                  <div class="text-caption">・Good要因詳細</div>
                  <v-textarea
                    v-model="evaluation.goodFactorDetail"
                    outlined
                    auto-grow
                    :readonly="readonly"
                    hide-details
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="px-3">
                  <div class="text-caption">・Bad要因</div>
                  <v-combobox
                    v-model="badFactor"
                    :items="MasterData.evaluationBadFactor"
                    placeholder=""
                    outlined
                    multiple
                    small-chips
                    deletable-chips
                    hide-details
                    :clearable="!readonly"
                    dense
                    class="affiliated-user"
                    :readonly="readonly"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" class="px-3 mb-5">
                  <div class="text-caption">・Bad要因詳細</div>
                  <v-textarea
                    v-model="evaluation.badFactorDetail"
                    outlined
                    auto-grow
                    :readonly="readonly"
                    hide-details
                    
                  ></v-textarea>
                </v-col>
                <!-- 総合評価 -->
                <v-col cols="12" class="mb-3">
                  <parts-element-label label="総合評価" />
                </v-col>
                <v-col cols="12" class="px-3">
                  <div class="text-caption">・自社評価</div>
                  <v-select
                    v-model="evaluation.btEvaluation"
                    :items="MasterData.evaluationBtEvaluation"
                    outlined
                    hide-details
                    dense
                    :readonly="readonly"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="px-3 mb-4">
                  <div class="text-caption">・総評コメント</div>
                  <v-textarea
                    v-model="evaluation.btEvaluationComment"
                    outlined
                    auto-grow
                    :readonly="readonly"
                    hide-details
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="px-3">
                  <div class="text-caption">・クライアント評価</div>
                  <v-select
                    v-model="evaluation.clientEvaluation"
                    :items="MasterData.evaluationClientEvaluation"
                    outlined
                    hide-details
                    dense
                    :readonly="readonly"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="px-3">
                  <div class="text-caption">・クライアント評価コメント	</div>
                  <v-textarea
                    v-model="evaluation.clientEvaluationComment"
                    outlined
                    auto-grow
                    :readonly="readonly"
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <!-- パーツ登録ボタン -->
        <v-row v-if="hasWritePermission" class="mb-6" justify="center">
          <div class="px-2">
            <ex-v-btn
              label="保存する"
              icon="mdi-plus-circle-outline"
              @click="save()"
              tile
              depressed
              color="btPrimary"
              class="white--text"
            />
          </div>
        </v-row>
        <v-row v-else class="mb-6" justify="center">
          <v-btn
            v-show="!!evaluation.overviewId"
            color="btPrimary"
            depressed
            class="white--text"
            @click="moveNote"
          >
            <v-icon class="me-1">mdi-notebook</v-icon>
            振り返りノートを確認する
          </v-btn>
        </v-row>
        <!-- 戻るボタン -->
        <v-row justify="center">
          <v-btn text color="btPrimary" dark @click="$router.back()">
            <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
            <span class="black--text">前のページに戻る</span>
          </v-btn>
        </v-row>
      </v-container>

      <!-- 登録確認ダイアログ -->
      <v-dialog v-model="dialog.updated" :width="600">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text class="text-subtitle-2" style="color: rgba(0, 0, 0, 0.87);">
            <div class="msg text-center">
              保存が完了しました。ノート入力に進みますか？
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-space-around">
            <v-btn
              depressed
              text
              @click="dialog.updated = false"
              width="180"
            >
              戻る
            </v-btn>
            <ex-v-btn
              label="ノート入力へ進む"
              icon="mdi-arrow-right-top-bold"
              @click="moveNote()"
              depressed
              color="btPrimary"
              class="white--text"
              width="180"
            />
            <ex-v-btn
              label="振り返りを完了する"
              icon="mdi-check-all"
              @click="dialog.complete = true"
              depressed
              color="btAccent"
              class="white--text"
              width="180"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 遷移確認ダイアログ -->
      <yes-no-dialog
        :message="'編集中のものは保存されませんが、よろしいですか？'"
        v-model="dialog.leave"
        yesText="保存せずに離れる"
        noText="キャンセル"
        center
        @clickYes="cb()"
        @clickNo="
          dialog.leave = false;
          cb(false);
        "
      />

      <evaluation-complete-dialog
        :value="dialog.complete"
        @input="closeAllDialog()"
      />
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "../../../api/RepositoryFactory";
import MasterData from "../../../utils/MasterData";

import VBtnAccent from "@/components/atomic/atoms/VBtnAccent.vue";
import PartsElementLabel from "../atoms/PartsElementLabel.vue";
import CustomCurrencyField from '../atoms/CustomCurrencyField';
import YesNoDialog from "../atoms/YesNoDialog.vue";
import ExVBtn from "../atoms/ExVBtn.vue";
import StrategyPartsRefBar from "../organisms/StrategyPartsRefBar.vue";
import EvaluationCompleteDialog from "../molecules/EvaluationCompleteDialog.vue";
import CommonAddBtn from "../atoms/CommonAddBtn.vue";

export default {
  props: ["paramSectionId"],
  components: {
    VBtnAccent,
    YesNoDialog,
    PartsElementLabel,
    CustomCurrencyField,
    ExVBtn,
    StrategyPartsRefBar,
    EvaluationCompleteDialog,
    CommonAddBtn
  },
  data() {
    return {
      isInit: false,
      needUpdate: false, //更新API呼出の要否フラグ
      dataInit: false,
      value: null,
      cb: null,
      expand: false,

      // v-dialogフラグ
      dialog: {
        updated: false,
        leave: false,
        complete: false,
      },

      dPickerTargetStartDate: false,
      dPickerTargetEndDate: false,

      // 振り返り情報
      evaluation: {
        targetStartDate: null,
        targetEndDate: null,
        termSalesTarget: null,
        term_sales_results: null,
        termCustomerTarget: null,
        termCustomerResults: null,
        termBudget: null,
        termUsage: null,
        goodFactor: null,
        goodFactorDetail: null,
        badFactor: null,
        badFactorDetail: null,
        btEvaluation: null,
        btEvaluationComment: null,
        clientEvaluation: null,
        clientEvaluationComment: null,
      },

      // 作成者情報
      sheetInfo: null,

      goodFactor: [],
      badFactor: [],

      MasterData,
    };
  },
  computed: {
    ...mapGetters(["project", "userInfo", "registeredSections", "hasWritePermission"]),

    readonly() { return !!this.project.archivedAt || !this.hasWritePermission },

    targetStartDate: {
      get() { 
        if(!this.evaluation.targetStartDate) return null;
        return this.$dayjs(this.evaluation.targetStartDate).format("YYYY-MM-DD");
      },
      set(val) { this.evaluation.targetStartDate = val },
    },

    targetEndDate: {
      get() { 
        if(!this.evaluation.targetEndDate) return null;
        return this.$dayjs(this.evaluation.targetEndDate).format("YYYY-MM-DD")
      },
      set(val) { this.evaluation.targetEndDate = val },
    },
  },
  methods: {
    ...mapActions(["getRegisteredSections"]),

    closeAllDialog() {
      for(let key in this.dialog) {
        this.dialog[key] = false;
      }
    },

    calcPercentage(dividend, divisor, digits = 2) {
      let rst = 0;

      if((dividend * divisor)) {
        rst = ((dividend / divisor * Math.pow(10, digits) * 100) / Math.pow(10, digits))
      }

      return rst.toFixed(2);
    },

    async register() {
    },

    beforeUnload(e) {
      if (this.needUpdate) {
        e.returnValue = "";
      }
    },

    async moveNote() {
      this.$router.push({name: "ReviewOverview", query: this.getQueryParamProjectReadonly() })
    },

    async save() {

      this.evaluation.targetStartDate = this.targetStartDate;
      this.evaluation.targetEndDate = this.targetEndDate;

      this.evaluation.goodFactor = this.packJson(this.goodFactor)
      this.evaluation.badFactor = this.packJson(this.badFactor)

      // 初期情報を保存する
      const body = {
        evaluation: this.evaluation,
        userId: this.userInfo.userId,
      }

      // APIからデータを取得する
      const repository = RepositoryFactory.get("evaluation");
      const response = await repository.updated(this.project.id, body);
      if (response.data.result && response.data.resultCode === "0000") {
        console.log("evaluation updated")
      } else {
        this._sendError(response.data.message);
      }

      // this.$store.commit('setToast', {msg: '保存しました。', color: 'green'});

      this.dialog.updated = true;
      this.needUpdate = false;
    },

    async fetchEvaluation() {
      // APIからデータを取得する
      const repository = RepositoryFactory.get("evaluation");
      const response = await repository.fetched(this.project.id);
      if (response.data.result && response.data.resultCode === "0000") {
        this.evaluation = response.data.data.evaluation;
        this.sheetInfo = response.data.data.sheetInfo;
      } else {
        this._sendError(response.data.message);
      }
    },

    addTermTarget() {
      if(!this.evaluation.termTarget) this.evaluation.termTarget = [];
      this.evaluation.termTarget.push({
        termTargetName: null,
        termTargetAmount: null,
        termResultsAmount: null,
        termTargetUnitprice: null,
        termResultsUnitprice: null,
      })
    },

    deleteTermTarget(idx) {
      this.evaluation.termTarget.splice(idx, 1);
    },

    extractJson(str) {
      if(typeof str !== "string" || !str) return []
      return JSON.parse(str);
    },

    packJson(obj) {
      return JSON.stringify(obj);
    },
  },
  async created() {
    await this.fetchEvaluation();

    // ステップ別目標達成が１件もない場合は追加する
    if(!this.project.archivedAt && !this.evaluation.termTarget?.length) {
      this.addTermTarget();
    } 

    // Jsonを変換
    this.goodFactor = this.extractJson(this.evaluation.goodFactor);
    this.badFactor = this.extractJson(this.evaluation.badFactor);

    this.dataInit = true;

    window.addEventListener("beforeunload", this.beforeUnload);

    this.isInit = true;
  },

  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },

  beforeRouteLeave(to, from, next) {
    if (this.needUpdate) {
      // コールバックを設定して遷移確認ダイアログを表示
      this.cb = next;
      this.dialog.leave = true;
    } else {
      next();
    }
  },

  watch: {
    evaluation: {
      handler() {
        if (this.dataInit) {
          this.needUpdate = true; //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.review-wrap {
  @include scrollAreaX();
  width: 100%;
  letter-spacing: 0.0125em;
  padding: 0 15px;

  .container {
    max-width: 1040px;
  }
}

</style>
